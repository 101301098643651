//const website = require('./website')

module.exports = {
  'fr-fr': {
    default: true,
    label: 'Fr',
    path: 'fr',
    locale: 'fr-fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    headline: '',
    espace: 'Espace',
    dl: 'Télécharger le dossier de presse'
  },
  'en-us': {
    default: false,
    label: 'Eng',
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US' ,
    espace: 'Space',
    dl: 'Download the press kit'
  }
}
