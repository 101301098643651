module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Espace Bertrand Grimont","short_name":"Bertrand Grimont","description":"Incubateur d’idées et d’audaces. Il défend un décloisonnement des pratiques et des regards en lisière de l’art.","start_url":"","theme_color":"#ffffff","display":"standalone","icon":"src/images/icons/favicon-96x96.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
