import React from "react"
// import Helmet from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby'
import Header from "./Header"
// import Footer from "./Footer"
import i18n from "../../config/i18n";

// import Helmet from "react-helmet"
// import { withPrefix } from 'gatsby'

//import {WrapperContext} from "../context/WrapperContext"

require("../styles/index.scss")

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale, template } }) => {
    const { options } = useStaticQuery(query)
    //console.log(options)
    return (
        <LocaleContext.Provider value={{ locale, i18n, options }}>
            <div id="page">
                {/* <Helmet>
                    <script type="text/javascript" src={withPrefix('splitting.min.js')} />
                </Helmet> */}
                <Header />
                <div className="deco-wrap">
                    <div className="row">
                        <div className="col-xs-3">
                            <div className="deco"></div>
                        </div>
                    </div>
                </div>
                <main>{children}</main>
            </div>
        </LocaleContext.Provider>
    )
};

export { LocaleContext, Layout };

const query = graphql`
    query {
        options: prismicOptions {
            ..._prismicOptions
        }
    }
`
