import React, { Component } from "react"
//import { StaticQuery, graphql } from "gatsby"
// import { StaticQuery, graphql } from "gatsby"
import { ContextHoc } from "../context/ContextHoc"
import LangSwitcher from "./ui/LangSwitcher"
import TitreSplit from "./ui/TitreSplit"

class Header extends Component {
    constructor(props) {
        super(props)
        //this.state = {}
        this._wheeling = this._wheeling.bind(this)
    }

    componentWillUnmount(){
        this.scrollWrapper.removeEventListener("wheel", this._wheeling)
    }

    componentDidMount(){
        this.scrollWrapper = document.querySelector(".coming-soon")
        this.scrollWrapper.addEventListener("wheel", this._wheeling)
    }

    _wheeling(e){
        const deltaY = Math.sign(e.deltaY)
        //console.log(deltaY)
        //console.log(this.scrollWrapper.scrollTop)
        if(this.scrollWrapper.scrollTop > 0){
            if(deltaY > 0){
                document.body.classList.add("downscroll")
            }
        }else{
            document.body.classList.remove("downscroll")
        }

        // clearTimeout(window._wheeling);
        // window._wheeling = setTimeout(() => {
        //     console.log(this.scrollWrapper.scrollTop)
        //     if(this.scrollWrapper.scrollTop === 0){
        //         document.body.classList.remove("downscroll")
        //     }
        // }, 260);
        
    }

    render() {
        const {context} = this.props
        const { short_name } = context.options.data
        // const locale = context.i18n[context.locale]
    // console.log(short_name)
        return (
            <header>
                <div className="row">
                    <div className="col-xs-3 ">
                        <div className="t-c">Espace</div>
                    </div>
                    <div className="col-xs-9">
                        <div className="b-b ">
                            <div className="row between-xs">
                                <div className="col-xs ">
                                    <div className="t-c">
                                        <TitreSplit texte={short_name.text} />
                                    </div>
                                </div>
                                <div className="col-xs ">
                                    <div className="t-c text-right">
                                    <LangSwitcher />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </header>
        )
    }
}

export default ContextHoc(Header)