import React, { Component } from 'react';
import { ContextHoc } from '../../context/ContextHoc'

class TitreSplit extends Component {
    componentDidMount(){
        //window.addEventListener('load', this._handleLoaded);
        
        // console.log(window.Splitting)
        if(window.Splitting){
            this._split()
        }else{
            setTimeout(() => {
                this._split()
            }, 500)
        }
    }

    _split(){
        const colors = this.props.context.options.data.colors.map(el => el.color)

        const target = this.refs.texte
        const split = window.Splitting({ target: target, by: 'chars' });
        // const colors = ['#FF0000', '#0000FF', '#FFE800', '#2CD9E1', '#68E12C', '#FF85C2']
        const len = colors.length
        let index = 0
        split.forEach( s => {
//   console.log(s)
            s.chars.forEach( (char, i) => {
                // console.log(index, len, colors[index])
                index = index < len - 1 
                ? index+1
                : 0

                char.style.setProperty('--color', colors[index]);
            });
            
          });
    }

    render() {
        const {texte} = this.props
        return (
            <div 
            ref="texte"
            className="texte-split">
                {texte}
            </div>
        );
    }
}

export default ContextHoc(TitreSplit);